// branding configuration
$publication: 'burtonmail';
$logo-dimensions: 600, 160;
$primary-color: #F1BB00;
$header-additional: #4D4D4F;

// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }

  .with-topbox {
    .mod-header .secondary ul a {
      color: $pitch-black;
    }
  }

  #recommendations-topbox {
    .teaser .headline,
    .advertising-shopwindow-link a {
      color: $pitch-black;
    }
  }

  #breaking-news {
    .header {
      .publication-theme {
        color: $pitch-black !important; // sass-lint:disable-line no-important
      }
    }
  }
}
